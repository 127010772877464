import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useCurrentUser from 'hooks/api/useCurrentUser';

import { post, put } from 'api/index';
import {
  CREATE_SCRATCH_JUDGE,
  FIND_EXERCISE_TEST_RESULT,
} from 'api/portfolioService';

import b64toBlob from 'utils/b64toBlob';
import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

import useParams from '../../useParams';
import useCreateScratchExercise from '../portfolioService/useCreateScratchExercise';

import createExercise from './createExercise';
import findOrCreate from './findOrCreateExercise';
import getExerciseById from './getExercise';

const STATUS = {
  LOADING: 'LOADING',
  SAVING: 'SAVING',
  RESTARTING: 'RESTARTING',
  ERROR: 'ERROR',
};

const createForm = (blob, data) => {
  const form = new FormData();

  form.append('data', JSON.stringify(data));
  form.append('file', blob);

  return form;
};

const urls = {
  POST_IMAGE: (id) => `${process.env.REACT_APP_API_URL}/exercises/${id}/image`,
  PUT: (id) => `${process.env.REACT_APP_API_URL}/exercises/${id}`,
  CREATE: `${process.env.REACT_APP_API_URL}/exercises`,
  RESTART: (exerciseId) =>
    `${process.env.REACT_APP_API_URL}/exercises/${exerciseId}/reset`,
};

const getExercise = (id, startId, language) => {
  if (startId) {
    return findOrCreate(startId, language);
  }

  if (id) {
    return getExerciseById(id);
  }

  return createExercise();
};

const useExercise = ({ id, startId }) => {
  const { language } = useParams();
  const { user } = useCurrentUser();
  const history = useHistory();
  const [status, setStatus] = useState();
  const [exercise, setExercise] = useState();
  const { mutate: createScratchExercise } = useCreateScratchExercise();
  const [testResults, setTestResults] = useState([]);

  // Loading states
  const [isLoadingTests, setLoadingTests] = useState(false);

  useEffect(() => {
    // if there is an exercise without an id  (the default exercise is loaded in)
    // and there is now an id: skip the render

    if (exercise && exercise.id === undefined && !!id) {
      return;
    }

    if (!status && !exercise?.blob) {
      setStatus(STATUS.LOADING);
      getExercise(id, startId, language)
        .then(({ base64EncodedBlob, ...exercise }) => {
          setExercise((prev) => ({
            userId: user.id,
            ...prev,
            ...exercise,
            blob: b64toBlob(base64EncodedBlob),
          }));
          setStatus();
        })
        .catch(() => setStatus(STATUS.ERROR));
    }
  }, [status, id, user.id, exercise, startId, language]);

  const saveAs = useCallback(
    (blob) => {
      const form = createForm(blob, {
        title: exercise?.title,
        startVersionId: null,
      });

      return post(urls.CREATE, form)
        .then(({ id }) => {
          setStatus();
          setExercise((prevExercise) => ({ ...prevExercise, id }));
          history.replace(`/exercises/${id}`);
        })
        .catch(() => setStatus(STATUS.ERROR));
    },
    [exercise?.title, history],
  );

  const uploadImage = (canvas, exerciseId) => {
    const form = new FormData();
    form.append('file', canvas);

    return post(urls.POST_IMAGE(exerciseId), form);
  };

  const save = useCallback(
    ({ exerciseBlob, canvasBlob }) => {
      setStatus(STATUS.SAVING);
      if (!exercise?.id) {
        return saveAs(exerciseBlob);
      }

      return Promise.all([
        uploadImage(canvasBlob, exercise.id),
        put(
          urls.PUT(exercise.id),
          createForm(exerciseBlob, { title: exercise.title }),
        ),
      ])
        .then(() => setStatus())
        .catch(() => setStatus(STATUS.ERROR));
    },
    [exercise?.id, exercise?.title, saveAs],
  );

  const restart = useCallback(() => {
    setStatus(STATUS.RESTARTING);

    return post(urls.RESTART(exercise?.id))
      .then((exercise) => {
        setExercise(exercise);
        history.replace(`/exercises/${exercise?.id}`);
        setStatus();
      })
      .catch(() => setStatus(STATUS.ERROR));
  }, [exercise?.id, history]);

  // TEST
  const onTest = ({ exerciseBlob, enableTestButton }) => {
    setLoadingTests(true);
    const client = portfolioServiceQueryClient;

    // Request Judge
    client
      .request(CREATE_SCRATCH_JUDGE, {
        exerciseVersionId: exercise.exerciseVersionId,
        testPlanId: exercise.testPlanId,
        file: exerciseBlob,
        language: language?.toUpperCase(),
      })
      .then((response) => {
        // When judge is requested, start polling for result every 5s

        // Get JudgeId from response
        const judgeId = response.createScratchJudge.id;

        // Push requested judge into results array
        setTestResults([
          {
            id: judgeId,
            time: new Date(),
            status: 'loading',
          },
          ...testResults,
        ]);

        // Track time of when we started polling so we can stop after 60s
        const startOfQuery = Date.now();

        // Save interval so we can cancel it
        const queryResultsEvery5Sec = setInterval(() => {
          client
            .request(FIND_EXERCISE_TEST_RESULT, {
              sessionId: judgeId,
            })
            .then(({ findScratchJudgeResults }) => {
              // When result is found update
              if (findScratchJudgeResults?.length > 0) {
                // Stop polling
                clearInterval(queryResultsEvery5Sec);

                // Stop loading animations
                setLoadingTests(false);

                // Update result in existing results array (state)
                setTestResults((oldResults) => {
                  // clone current testResults for immutabillity
                  const newResults = [...oldResults];

                  // Its an object so we can just update it, it will stay linked
                  const currentJudge = newResults.find(
                    (result) => result.id === judgeId,
                  );
                  if (currentJudge) {
                    currentJudge.result = JSON.parse(
                      findScratchJudgeResults[0].result,
                    );
                    currentJudge.status = 'done';
                  }

                  return newResults;
                });

                // Re-enable test button so user can click it
                enableTestButton();
              }
            })
            .catch(() => {
              // if server throws error stop checking and re-enable button
              clearInterval(queryResultsEvery5Sec);
              setLoadingTests(false);

              // Update result in existing results array (state)
              setTestResults((oldResults) => {
                // clone current testResults for immutabillity
                const newResults = [...oldResults];

                // Its an object so we can just update it, it will stay linked
                const currentJudge = newResults.find(
                  (result) => result.id === judgeId,
                );
                if (currentJudge) {
                  currentJudge.status = 'failed';
                }

                return newResults;
              });

              // Re-enable test button so user can click it
              enableTestButton();
            });

          // If judge takes more then 60s something must have gone wrong,
          // stop checking and re-enable button
          if (Date.now() - startOfQuery > 60000) {
            clearInterval(queryResultsEvery5Sec);
            setLoadingTests(false);

            // Update result in existing results array (state)
            setTestResults((oldResults) => {
              // clone current testResults for immutabillity
              const newResults = [...oldResults];

              // Its an object so we can just update it, it will stay linked
              const currentJudge = newResults.find(
                (result) => result.id === judgeId,
              );
              if (currentJudge) {
                currentJudge.status = 'failed';
              }

              return newResults;
            });

            // Re-enable test button so user can click it
            enableTestButton();
          }
        }, 5000);
      });
  };

  return {
    exercise: {
      ...exercise,
      setTitle: (title) => setExercise((e) => ({ ...e, title })),
      testResults,
    },
    save,
    restart,
    isLoading: !exercise?.blob,
    isLoadingTests,
    isSaving: status === STATUS.SAVING,
    isError: status === STATUS.ERROR,
    isRestarting: status === STATUS.RESTARTING,
    test: onTest,
    onExerciseLoaded: (exerciseBlob) =>
      createScratchExercise({
        exerciseVersionId: exercise.exerciseVersionId,
        exerciseId: exercise.exerciseId,
        file: exerciseBlob,
      }),
  };
};

export default useExercise;

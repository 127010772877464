import { externalEn } from './external';

export default {
  // Global

  'global.class': 'Class',
  'global.go_back': 'Go back',
  'global.given_by': 'Given by',
  'global.on': 'on',
  'global.view': 'View',
  'global.indicator': 'indicator',
  'global.redirecting': 'Redirecting...',
  'global.save': 'Save',
  'global.save-changes': 'Save changes',
  'global.try-again': 'Try again',
  'global.oops': 'Oops',
  'global.cancel': 'Cancel',
  'global.hack_room': 'Hack room',
  'global.empty': "Looks like nothing's here",
  'global.no_results': 'No results found',
  'global.edit': 'Edit',
  'global.delete': 'Delete',
  'global.required-field': 'Required field',
  'dropdown.no_results': "No options found",
  'global.export': 'Export',
  'global.loading': 'Loading...',

  'global.LESSON.plural': 'Lessons',
  'global.LESSON.singular': 'Lesson',
  'global.LESSON.view': 'View lesson',

  'global.EXAM.plural': 'Exams',
  'global.EXAM.singular': 'Exam',
  'global.EXAM.view': 'View exam',

  'global.STUDENT.plural': 'Students',
  'global.CLASS.plural': "Classes",
  'global.TEACHER.plural': "Teachers",

  // viewModes
  'view-modes.projection': 'Projection',
  'view-modes.selfstudy': 'Self study',
  'view-modes.classical': 'Classical',

  // Header
  'header.navigation.students': 'Pupils',
  'header.navigation.lessons': 'Lessons',
  'header.navigation.exams': 'Exams',
  'header.navigation.exercises': 'Exercises',
  'header.navigation.hack_room': 'Hack room',
  'header.navigation.studio': 'Studio',
  'header.hello': 'Hello, ',
  'header.profile': 'Profile',
  'header.logout': 'Log out',
  'header.manage_school': 'Manage school',
  'header.old_manage_school': 'Old manage panel',
  'header.help_videos': 'Help videos',
  'header.help': 'Help',

  // Content
  'content.view_lesson': 'View lesson',
  'content.overview': 'Settings',
  'content.results': 'Results',

  // Content overview
  'content-details.status': 'Status',

  // Results overview
  'results-overview.title.results': 'Results',
  'results-overview.title.class': 'Class',
  'results-overview.column.name': 'Name',
  'results-overview.column.submitted_at': 'Submitted at',
  'results-overview.column.results': 'Results',
  'results-overview.column.progress': 'Progress',
  'results-overview.view': 'view',
  'results-overview.home': 'home',
  'results-overview.class': 'class',
  'results-overview.publish_results': 'Publish results',
  'results-overview.unpublish_results': 'Hide results',
  'results-overview.publish_results_warnings': 'Warning',
  'results-overview.export_successful': 'Export completed successfully',
  'results-overview.export_failed': 'Export failed',
  'results-overview.export_smartschool': 'Score Points book',
  'results-overview.export_excel': 'Excel Spreadsheet',
  'results-overview.student_has': 'student has',
  'results-overview.students_have': 'students have',
  'results-overview.not_all_questions_answered_warning': 'not answered all questions.',
  'results-overview.not_all_questions_have_scores_warning': 'unscored questions',
  'results-overview.column.score': 'Score',
  'results-overview.no-results': 'No answers have been submitted',
  'results-overview.no_complete_average': 'This is a temporary average because not all questions are graded',
  'results-overview.not_fully_graded': 'Not all answeres are graded',
  'results-overview.not_fully_submitted': 'The student has not yet submitted all answers',
  'results-overview.average': 'Average',
  'results-overview.version': 'Version',
  'results-overview.made_in_version': 'Made in version',
  'results-overview.unanswered_questions': 'unanswered questions',
  'results-overview.unanswered_question': 'unanswered question',

  'student-answers.title.results': 'Results',
  'student-answers.title.total': 'Total',
  'student-answers.question_label': 'question',
  'student-answers.no_questions': 'There are no questions in this view mode.',
  'student-answers.no_results_published': 'There are no published results yet.',
  'student-answers.given_answer': 'Given answer',
  'student-answers.example_solution': 'Example solution',
  'student-answers.click_to_show_question': 'Show question',
  'student-answers.click_to_hide_question': 'Hide question',
  'student-answers.no_content': 'Nothing to show',

  'question-result.no-result': 'There is no answer for this question',

  // StudentSwticher
  'student-switcher.previous': 'previous',
  'student-switcher.next': 'next',

  // Errors
  'errors.no-page': 'No page was found with that URL',
  'errors.change_language': `Couldn't update language`,
  'errors.change_eula': `Couldn't update EULA`,
  'errors.no_classgroups': 'You are not associated with a class. Contact your school administrator.',
  'errors.no_licence': 'You do not have a licence for a Lesson, please return to the store to purchase a licencence.',
  'errors.redirect_store_part_1': 'please follow ', 
  'errors.redirect_store_part_2': 'this link', 
  'errors.redirect_store_part_3': '.', 

  'errors.set_results_published': `Couldn't set results published`,
  'errors.set_publish_status': `Couldn't update publish status`,
  'errors.update_score': `Couldn't update score`,

  // publishStatuses
  'publish-statuses.OPEN_FOR_HOME': 'Open for selfstudy',
  'publish-statuses.OPEN_FOR_CLASSGROUP': 'Open for classgroup',
  'publish-statuses.CLOSED': 'Closed',
  'publish-statuses.explanation.OPEN_FOR_HOME': 'The students see the self study version of the lesson',
  'publish-statuses.explanation.OPEN_FOR_CLASSGROUP': 'The students see the class version of the lesson',
  'publish-statuses.explanation.CLOSED': "The students don't see this lesson",

  // curricula
  'curricula.overview': 'Overview',
  'curricula.LESSON.title': 'Lesson overview',
  'curricula.LESSON.content-overview.TEACHER.empty': 'No lessons have been published yet',
  'curricula.LESSON.content-overview.STUDENT.empty': 'There are no open lessons yet',

  'curricula.EXAM.title': 'Exam overview',
  'curricula.EXAM.content-overview.TEACHER.empty': 'No exams have been published yet.',
  'curricula.EXAM.content-overview.STUDENT.empty': 'There are no open exams yet',

  // Profile
  'profile.title': 'Profile',
  'profile.first_name': 'First name',
  'profile.last_name': 'Last name',
  'profile.username': 'Username',
  'profile.mail': 'Mail',
  'profile.no_mail': 'No mail povided',
  'profile.eula_accepted': 'End User License Agreement Accepted',
  'profile.language': 'Language',

  'profile.change_password': 'Change password',
  'profile.current_password': 'Current password',
  'profile.new_password': 'New password',
  'profile.confirm_new_password': 'Confirm new password',

  'profile.errors.passwords_not_matching': 'The new passwords do not match',
  'profile.errors.wrong_current_password': 'Your current password is incorrect',
  'profile.errors.change_password': `Couldn't change password`,
  'profile.errors.something_went_wrong': "The user's details cannot be shown at the moment",
  'profile.success.change_password': 'Successfully changed password',

  // HelpVideos
  'help_videos.title': 'Help videos',

  // ContentSlideViewer
  'content-slide-viewer.no-slides': 'No slides',
  'content-slide-viewer.no-slides.description': 'No slides seem available for this view mode. Please try another one:',
  'content-slide-viewer.no-slides.description-student': 'This lesson does not seem to contain any slides.',
  'content-slide-viewer.forgot-to-save': 'Forgot to submit?',
  'content-slide-viewer.forgot-to-save-description': 'The answer to this question has not yet been saved. Do you wish to submit?',

  // ContentSlideViewer ExamSlide
  'content-slide-viewer.hard-submit-slide.title': 'Submit',
  'content-slide-viewer.hard-submit-slide.description': 'When you submit the exam, it will no longer be possible to change your answers. Are you sure you want to submit?',
  'content-slide-viewer.hard-submit-slide.submit': 'Submit',
  'content-slide-viewer.hard-submit-slide.submitted': 'Submitted',
  'content-slide-viewer.hard-submit-slide.success': 'The exam has been successfully submitted',
  'content-slide-viewer.hard-submit-slide.error': 'Oops! Something went wrong while submitting the test. Try again',

  // Scratch
  'scratch.header.new_project': 'New project',
  'scratch.header.start_over': 'Start over',
  'scratch.header.upload-exercise': 'Upload exercise',
  'scratch.header.turbo': 'Turbo',
  'scratch.header.by': 'By',
  'scratch.scratch_error': 'Your Scratch exercise could not be loaded...',
  'scratch.confirmation.content': 'Are you sure you want to reset the exercise?',

  // Student overview
  'student-overview.title': 'Students',
  'student-overview.column.name': 'Name',
  'student-overview.column.username': 'Username',
  'student-overview.column.last-login': 'Last login',
  'student-overview.column.class': 'Class',
  'student-overview.column.reset-password.header': 'Reset password',
  'student-overview.column.reset-password.info': 'Confirm with your own password to reset the desired password',
  'student-overview.column.reset-password.your-password-label': 'Password',
  'student-overview.column.reset-password.confirm': 'Reset',
  'student-overview.column.reset-password.error': 'Error whilst resetting password',
  'student-overview.column.delete-student.success': "User successfully deleted",
  'student-overview.column.delete-student.error': "Something went wrong whilst deleting the student",
  'student-overview.column.edit-student.success': "User successfully edited",
  'student-overview.column.edit-student.error': "Something went wrong whilst editing the student",
  'student-overview.column.reset-password.confirmation': 'New password was copied to your clipboard',
  'student-overview.new-student': "New student",
  'student-overview.add-student': "Add student",
  'student-overview.no-students': "This class contains no students",
  'student-overview.column.create-student.success': "User successfully created. Password copied to clipboard",
  'student-overview.column.create-student.error': "Something went wrong whilst creating the student",
  'student-overview.are-you-sure': "Are you sure you want to delete this user?",

  // Add user dialog
  'add-student-dialog.header': "Create new student",
  'add-student-dialog.success': "Successfully created new student",
  'add-student-dialog.error': "Something went wrong when creating a new student",
  'add-student-dialog.confirm': "Create",
  'add-student-dialog.linked_classgroups': "Linked classgroups",

  // hack room
  'hack-room.featured': 'Featured',
  'hack-room.more_lessons': 'More lessons',
  'hack-room.what_are_you_looking_for': 'What are you looking for?',

  //Exercises
  'exercises-overview.title': 'Exercises of',
  'exercises-overview.pretitle': 'Exercises',
  'exercises-overview.my-exercises.title': 'My exercises',
  'exercises-overview.no_exercises': 'No exercises available',
  'exercises-overview.create': 'Create a new exercise',
  'exercises-overview.error': 'An error occured while loading the exercises',
  'exercises-overview.view_exercises': 'View exercises',
  'exercises-overview.delete_success': 'Successfully deleted exercise',
  'exercises-overview.delete_fail': 'Error while deleting exercise',
  'exercises-overview.toggle_filter': 'Filter',
  'exercises-overview.filter_placeholder': 'Title',

  // scratch gui
  'gui.ftrprf.testresult.test': 'Test',
  'gui.ftrprf.testresult.tests': 'Tests',
  'gui.ftrprf.testresult.show_subtests': 'Show all checks',
  'gui.ftrprf.testresult.hide_subtests': 'Hide all checks',
  'gui.ftrprf.testresult.click_to_test': 'Test your code!',
  'gui.ftrprf.testtab': 'Check (beta)',
  'gui.ftrprf.testtab.loading': 'Checking your code...',

  // excercise versions
  'scratch.version.STARTER': 'Starter',
  'scratch.version.SOLUTION': 'Solution',
  'scratch.version.DEMO': 'Demo',
  'scratch.version.EXTRA': 'Extra',
  'scratch.version.OTHER': 'Other',

  //Manage
  'manage.title': "Manage",
  'manage.edit-school': "Edit school",

  // External
  ...externalEn,
};

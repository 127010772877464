import { externalNl } from './external';

export default {
  // Global

  'global.class': 'Klas',
  'global.go_back': 'Ga terug',
  'global.given_by': 'Gegeven door',
  'global.on': 'op',
  'global.view': 'Bekijk',
  'global.indicator': 'indicator',
  'global.redirecting': 'Doorsturen...',
  'global.save': 'Sla op',
  'global.save-changes': 'Sla wijzigingen op',
  'global.try-again': 'Probeer opnieuw',
  'global.oops': 'Oeps',
  'global.cancel': 'Annuleren',
  'global.hack_room': 'Hack room',
  'global.empty': 'Het lijkt erop dat niets te vinden is',
  'global.no_results': 'Geen resultaten gevonden',
  'global.edit': 'Bewerk',
  'global.delete': 'Verwijder',
  'global.required-field': 'Verplicht veld',
  'dropdown.no_results': "Geen opties gevonden",
  'global.export': 'Exporteer',
  'global.loading': 'Laden...',

  'global.LESSON.plural': 'Lessen',
  'global.LESSON.singular': 'Les',
  'global.LESSON.view': 'Bekijk les',

  'global.EXAM.plural': 'Toetsen',
  'global.EXAM.singular': 'Toets',
  'global.EXAM.view': 'Bekijk toets',

  'global.STUDENT.plural': 'Studenten',
  'global.CLASS.plural': "Klassen",
  'global.TEACHER.plural': "Leerkrachten",

  // viewModes
  'view-modes.projection': 'Projectie',
  'view-modes.selfstudy': 'Zelfstudie',
  'view-modes.classical': 'Klassikaal',

  // Header
  'header.navigation.students': 'Leerlingen',
  'header.navigation.lessons': 'Leer',
  'header.navigation.exams': 'Test',
  'header.navigation.exercises': 'Codeer',
  'header.navigation.hack_room': 'Hack room',
  'header.navigation.studio': 'Studio',
  'header.hello': 'Hallo, ',
  'header.profile': 'Profiel',
  'header.logout': 'Uitloggen',
  'header.manage_school': 'Beheer school',
  'header.old_manage_school': 'Oud beheer paneel',
  'header.help_videos': "Hulpvideo's",
  'header.help': 'Hulp',

  // Content
  'content.view_lesson': 'Bekijk les',
  'content.overview': 'Instellingen', // this is called 'Instellingen' instead of 'Overzicht' because at the moment, we can only set the view mode on the overview.
  'content.results': 'Resultaten',

  // Content overview
  'content-details.status': 'Status',

  // Results overview
  'results-overview.title.results': 'Resultaten',
  'results-overview.title.class': 'Klas',
  'results-overview.column.name': 'Naam',
  'results-overview.column.submitted_at': 'Ingezonden op',
  'results-overview.column.results': 'Resultaten',
  'results-overview.column.progress': 'Vooruitgang',
  'results-overview.view': 'bekijk',
  'results-overview.home': 'thuis',
  'results-overview.class': 'klas',
  'results-overview.publish_results': 'Publiceer resultaten',
  'results-overview.unpublish_results': 'Verberg resultaten',
  'results-overview.publish_results_warnings': 'Waarschuwing',
  'results-overview.export_successful': 'Export met succes voltooid',
  'results-overview.export_failed': 'Exporteren mislukt',
  'results-overview.export_smartschool': 'Skore Puntenboekje',
  'results-overview.export_excel': 'Excel Spreadsheet',
  'results-overview.student_has': 'leerling heeft',
  'results-overview.students_have': 'leerlingen hebben',
  'results-overview.not_all_questions_answered_warning': 'niet alle vragen beantwoord.',
  'results-overview.not_all_questions_have_scores_warning': 'onverbeterde vragen.',
  'results-overview.column.score': 'Score',
  'results-overview.no-results': 'Er zijn geen antwoorden ingediend',
  'results-overview.no_complete_average': 'Dit is een tijdelijk gemiddelde omdat niet alle vragen verbeterd zijn',
  'results-overview.not_fully_graded': 'Niet alle antwoorden zijn verbeterd',
  'results-overview.not_fully_submitted': 'De student heeft nog niet alle antwoorden ingediend',
  'results-overview.average': 'Gemiddelde',
  'results-overview.version': 'Versie',
  'results-overview.made_in_version': 'Gemaakt in versie',
  'results-overview.unanswered_questions': 'onbeantwoorde vragen',
  'results-overview.unanswered_question': 'onbeantwoorde vraag',

  'student-answers.title.results': 'Resultaten',
  'student-answers.title.total': 'Totaal',
  'student-answers.question_label': 'vraag',
  'student-answers.no_questions': 'Er zijn geen vragen in deze weergave.',
  'student-answers.no_results_published': 'Er zijn nog geen resultaten gepubliceerd.',
  'student-answers.given_answer': 'Ingevuld antwoord',
  'student-answers.example_solution': 'Voorbeeldoplossing',
  'student-answers.click_to_show_question': 'Toon vraag',
  'student-answers.click_to_hide_question': 'Verberg vraag',
  'student-answers.no_content': 'Niets om te tonen',

  'question-result.no-result': 'Er is geen antwoord voor deze vraag',

  // StudentSwitcher
  'student-switcher.previous': 'vorige',
  'student-switcher.next': 'volgende',

  // Errors
  'errors.no-page': 'Geen pagina gevonden met die URL',
  'errors.change_language': 'Kon taal niet wijzigen',
  'errors.change_eula': 'kon gebruikersvoorwaarden niet wijzigen',
  'errors.no_classgroups': 'U bent niet gekoppeld aan een klas. Contacteer uw schoolbeheerder.',
  'errors.no_licence': 'Je hebt geen licentie voor een les, ga terug naar de winkel om een ​​licentie te kopen.',
  'errors.redirect_store_part_1': 'Gelieve', 
  'errors.redirect_store_part_2': 'deze link ', 
  'errors.redirect_store_part_3': 'te volgen.',   'errors.set_results_published': 'Kon resultaten niet publiceren',
  'errors.set_publish_status': 'Kon les status niet aanpassen',
  'errors.update_score': 'Kon score niet updaten',

  // publishStatuses
  'publish-statuses.OPEN_FOR_HOME': 'Open voor zelfstudie',
  'publish-statuses.OPEN_FOR_CLASSGROUP': 'Open voor klas',
  'publish-statuses.CLOSED': 'Gesloten',
  'publish-statuses.explanation.OPEN_FOR_HOME': 'De leerlingen zien de zelfstudie versie van de les',
  'publish-statuses.explanation.OPEN_FOR_CLASSGROUP': 'De leerlingen zien de klassikale versie van de les',
  'publish-statuses.explanation.CLOSED': 'De leerlingen zien deze les niet',

  // curricula
  'curricula.overview': 'Overzicht',
  'curricula.LESSON.title': 'Lesoverzicht',
  'curricula.LESSON.content-overview.TEACHER.empty': 'Er zijn nog geen lessen gepubliceerd',
  'curricula.LESSON.content-overview.STUDENT.empty': 'Er staan nog geen lessen open',

  'curricula.EXAM.title': 'Toetsenoverzicht',
  'curricula.EXAM.content-overview.TEACHER.empty': 'Er zijn nog geen toetsen gepubliceerd',
  'curricula.EXAM.content-overview.STUDENT.empty': 'Er staan nog geen toetsen open',

  // Profile
  'profile.title': 'Profiel',
  'profile.first_name': 'Voornaam',
  'profile.last_name': 'Achternaam',
  'profile.username': 'Gebruikersnaam',
  'profile.mail': 'Mail',
  'profile.no_mail': 'Geen mailadres',
  'profile.eula_accepted': 'Gebruikersvoorwaarden geaccepteerd',
  'profile.language': 'Taal',
  'profile.change_password': 'Wijzig wachtwoord',
  'profile.current_password': 'Huidig wachtwoord',
  'profile.new_password': 'Nieuw wachtwoord',
  'profile.confirm_new_password': 'Nieuw wachtwoord bevestigen',

  'profile.errors.passwords_not_matching': 'De nieuwe wachtwoorden komen niet overeen',
  'profile.errors.wrong_current_password': 'Uw huidig wachtwoord is foutief',
  'profile.errors.change_password': 'Het wachtwoord kon niet gewijzigd worden.',
  'profile.errors.something_went_wrong': 'De details van de gebruiker konden niet getoond worden.',

  'profile.success.change_password': 'Uw wachtwoord is successvol gewijzigd',

  // HelpVideos
  'help_videos.title': "Hulpvideo's",

  // ContentSlideViewer
  'content-slide-viewer.no-slides': 'Geen slides',
  'content-slide-viewer.no-slides.description': 'Deze les bevat geen slides voor de huidige view mode. Kies een andere:',
  'content-slide-viewer.no-slides.description-student': 'Deze les lijkt geen slides te bevatten',
  'content-slide-viewer.forgot-to-save': 'Vergeten indienen?',
  'content-slide-viewer.forgot-to-save-description': 'Je antwoord op deze vraag is nog niet ingediend. Wens je in te dienen?',

  // ContentSlideViewer HardSubmitSlide
  'content-slide-viewer.hard-submit-slide.title': 'Indienen',
  'content-slide-viewer.hard-submit-slide.description': 'Bij het indienen van de toets zal het niet meer mogelijk zijn om je antwoorden te wijzigen. Ben je zeker dat je wil indienen?',
  'content-slide-viewer.hard-submit-slide.submit': 'Dien in',
  'content-slide-viewer.hard-submit-slide.submitted': 'Ingediend',
  'content-slide-viewer.hard-submit-slide.success': 'De toets is succesvol ingediend',
  'content-slide-viewer.hard-submit-slide.error': 'Er ging iets mis bij het indienen van de toets. Probeer opnieuw',

  // Scratch
  'scratch.header.new_project': 'New project',
  'scratch.header.start_over': 'Begin opnieuw',
  'scratch.header.upload-exercise': 'Upload oefening',
  'scratch.header.turbo': 'Turbo',
  'scratch.header.by': 'Door',
  'scratch.scratch_error': 'Je Scratch oefening kon niet geladen worden...',
  'scratch.confirmation.content': 'Ben je zeker dat je de oefening wilt herstarten?',

  // Student overview
  'student-overview.title': 'Studenten',
  'student-overview.column.name': 'Naam',
  'student-overview.column.username': 'Gebruikersnaam',
  'student-overview.column.last-login': 'Laatst ingelogd',
  'student-overview.column.class': 'Klas',
  'student-overview.column.reset-password.header': 'Reset wachtwoord',
  'student-overview.column.reset-password.info': 'Bevestig met je eigen wachtwoord om de reset uit te voeren',
  'student-overview.column.reset-password.your-password-label': 'Wachtwoord',
  'student-overview.column.reset-password.confirm': 'Reset',
  'student-overview.column.reset-password.error': 'Fout bij resetten van wachtwoord',
  'student-overview.column.delete-student.success': "Student werd verwijderd",
  'student-overview.column.delete-student.error': "Er ging iets mis tijdens het verwijderen",
  'student-overview.column.edit-student.success': "Student werd gewijzigd",
  'student-overview.column.edit-student.error': "Er ging iets mis tijdens het wijzigen",
  'student-overview.column.reset-password.confirmation': 'Het nieuwe wachtwoord werd gekopieerd naar je klembord',
  'student-overview.new-student': "Nieuwe student",
  'student-overview.add-student': "Voeg student toe",
  'student-overview.no-students': "Deze klas bevat geen studenten",
  'student-overview.column.create-student.success': "Nieuwe student aangemaakt, wachtwoord gekopieerd naar klembord",
  'student-overview.column.create-student.error': "Er ging iets mis tijdens het aanmaken",
  'student-overview.are-you-sure': "Ben je zeker dat je deze student wilt verwijderen?",

  // Add user dialog
  'add-student-dialog.header': "Create new student",
  'add-student-dialog.success': "Successfully created new student",
  'add-student-dialog.error': "Something went wrong when creating a new student",
  'add-student-dialog.confirm': "Create",
  'add-student-dialog.linked_classgroups': "Linked classgroups",

  // hack room
  'hack-room.featured': 'Uitgelicht',
  'hack-room.more_lessons': 'Meer lessen',
  'hack-room.what_are_you_looking_for': 'Wat zoek je',

  //Exercises
  'exercises-overview.title': 'Oefeningen van',
  'exercises-overview.pretitle': 'Oefeningen',
  'exercises-overview.my-exercises.title': 'Mijn oefeningen',
  'exercises-overview.no_exercises': 'Geen oefeningen beschikbaar',
  'exercises-overview.create': 'Maak een nieuwe oefening',
  'exercises-overview.error': 'Fout bij het laden van de oefeningen',
  'exercises-overview.view_exercises': 'Bekijk oefeningen',
  'exercises-overview.delete_success': 'De oefening werd verwijderd',
  'exercises-overview.delete_fail': 'Fout bij verwijderen van oefening',
  'exercises-overview.toggle_filter': 'Filter',
  'exercises-overview.filter_placeholder': 'Titel',

  // scratch gui
  'gui.ftrprf.testresult.test': 'Test',
  'gui.ftrprf.testresult.tests': 'Testen',
  'gui.ftrprf.testresult.show_subtests': 'Toon alle deeltesten',
  'gui.ftrprf.testresult.hide_subtests': 'Verberg alle deeltesten',
  'gui.ftrprf.testresult.click_to_test': 'Test je code!',
  'gui.ftrprf.testtab': 'Controleren (beta)',
  'gui.ftrprf.testtab.loading': 'Aan het controleren...',

  // excercise versions
  'scratch.version.STARTER': 'Starter',
  'scratch.version.SOLUTION': 'Oplossing',
  'scratch.version.DEMO': 'Demo',
  'scratch.version.EXTRA': 'Extra',
  'scratch.version.OTHER': 'Andere',

  //Manage
  'manage.title': "Beheer",
  'manage.edit-school': "Wijzig school",

  // External
  ...externalNl,
};
